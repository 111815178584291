import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from './App';

import   store   from './store/index';

const MainApp = () => (
	<Provider store={store}>
	 
			<Router>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</Router>
		 
	</Provider>
);

export default MainApp;