export const DATA_USER = 'DATA_USER';
export const DATA_USER_SUCCESS = 'DATA_USER_SUCCESS';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DATA_ROLE = 'DATA_ROLE';
export const DATA_ROLE_SUCCESS = 'DATA_ROLE_SUCCESS';

export const DATA_MYORDER = 'DATA_MYORDER';
export const DATA_MYORDER_SUCCESS = 'DATA_MYORDER_SUCCESS';
export const DATA_MYORDER_FAILURE = 'DATA_MYORDER_FAILURE';

export const DATA_PENDINGORDER = 'DATA_PENDINGORDER';
export const DATA_PENDINGORDER_SUCCESS = 'DATA_PENDINGORDER_SUCCESS';
export const DATA_PENDINGORDER_FAILURE = 'DATA_PENDINGORDER_FAILURE';

export const LIST_CELEBRITY = 'LIST_CELEBRITY';
export const LIST_CELEBRITY_SUCCESS = 'LIST_CELEBRITY_SUCCESS';
export const LIST_CELEBRITY_FAILURE = 'LIST_CELEBRITY_FAILURE';

export const LIST_CELEBRITY_ADDRESS = 'LIST_CELEBRITY_ADDRESS';
export const LIST_CELEBRITY_ADDRESS_SUCCESS = 'LIST_CELEBRITY_ADDRESS_SUCCESS';
export const LIST_CELEBRITY_ADDRESS_FAILURE = 'LIST_CELEBRITY_ADDRESS_FAILURE';

export const LIST_SKUDETAILS = 'LIST_SKUDETAILS';
export const LIST_SKUDETAILS_SUCCESS = 'LIST_SKUDETAILS_SUCCESS';
export const LIST_SKUDETAILS_FAILURE = 'LIST_SKUDETAILS_FAILURE';
export const DATA_ORDERSEARCH = 'DATA_ORDERSEARCH';
export const DATA_ORDERSEARCH_SUCCESS = 'DATA_ORDERSEARCH_SUCCESS';
export const DATA_ORDERSEARCH_FAILURE = 'DATA_ORDERSEARCH_FAILURE';

export const DATA_ORDERCONFIRM = 'DATA_ORDERCONFIRM';
export const DATA_ORDERCONFIRM_SUCCESS = 'DATA_ORDERCONFIRM_SUCCESS';
export const DATA_ORDERCONFIRM_FAILURE = 'DATA_ORDERCONFIRM_FAILURE';

export const ORDER_CONFIRM = 'ORDER_CONFIRM';
export const ORDER_CANCEL = 'ORDER_CANCEL';

export const GET_EDITORDERDATA = 'GET_EDITORDERDATA';
export const DATA_EDITORDER = 'DATA_EDITORDER';
export const DATA_EDITORDER_SUCCESS= 'DATA_EDITORDER_SUCCESS';
export const  DATA_EDITORDER_FAILURE = 'DATA_EDITORDER_FAILURE';

export const LIST_MAPPEDAGENT = 'LIST_MAPPEDAGENT';
export const LIST_MAPPEDAGENT_SUCCESS = 'LIST_MAPPEDAGENT_SUCCESS';
export const LIST_MAPPEDAGENT_FAILURE = 'LIST_MAPPEDAGENT_FAILURE';

export const LIST_ORDERSTATUS = 'LIST_ORDERSTATUS';
export const LIST_ORDERSTATUS_SUCCESS = 'LIST_ORDERSTATUS_SUCCESS';
export const LIST_ORDERSTATUS_FAILURE = 'LIST_ORDERSTATUS_FAILURE';

export const PENDING_ORDER_SKU_DELETION = 'PENDING_ORDER_SKU_DELETION';
export const PENDING_ORDER_SKU_DELETION_SUCCESS = 'LIST_ORDERSTATUS_SUCCESS';
export const PENDING_ORDER_SKU_FAILURE = 'LIST_ORDERSTATUS_FAILURE';

export const ALL_CELEBRITY = 'ALL_CELEBRITY';
export const ALL_CELEBRITY_SUCCESS = 'ALL_CELEBRITY_SUCCESS';
export const ALL_CELEBRITY_FAILURE = 'ALL_CELEBRITY_FAILURE';

export const ListJuniorRM = 'ListJuniorRM';
export const ListJuniorRMSuccess = 'ListJuniorRMSuccess';
export const ListJuniorRMFailure = 'ListJuniorRMFailure';

export const ListCelebUsers = 'ListCelebUsers';
export const ListCelebUsersSuccess = 'ListCelebUsersSuccess';
export const ListCelebUsersFailure = 'ListCelebUsersFailure';