export const RoleNames ={
    CelebAdmin : 1,
    CelebSeniorRM:2,
    CelebJuniorRM : 3,
    CelebAccountManager : 4,
    CelebSuperAdmin : 14,
    BoosterManager:5,
    BoosterProjectLeader:6,
    BoosterManagement:7,
    NotifyMeSendSms:11

}

export const Roles ={
    CelebAdmin : {roleId:1,roleName:"CelebAdmin"},
    CelebSeniorRM:{roleId:2,roleName:"CelebSeniorRM"},
    CelebJuniorRM :{roleId:3,roleName:"CelebJuniorRM"} ,
    CelebAccountManager : {roleId:4,roleName:"CelebAccountManager"},
    CelebSuperAdmin : {roleId:14,roleName:"CelebSuperAdmin"},
    BoosterManager:{roleId:5,roleName:"BoosterManager"},
    BoosterProjectLeader:{roleId:6,roleName:"BoosterProjectLeader"},
    BoosterManagement:{roleId:7,roleName:"BoosterManagement"},
    NotifyMeSendSms:{roleId:11,roleName:"NotifyMeSendSms"}
}