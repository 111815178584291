export const PROD_DEPT_CREATE = 'PROD_DEPT_CREATE';
export const PROD_DEPT_SUCCESS = 'PROD_DEPT_SUCCESS';
export const PROD_DEPT_FAILURE = 'PROD_DEPT_FAILURE';

export const BRANDS_CREATE = 'BRANDS_CREATE';
export const BRANDS_SUCCESS = 'BRANDS_SUCCESS';
export const BRANDS_FAILURE = 'BRANDS_FAILURE';

export const PROD_CATEGORY_CREATE = 'PROD_CATEGORY_CREATE';
export const PROD_CATEGORY_SUCCESS = 'PROD_CATEGORY_SUCCESS';
export const PROD_CATEGORY_FAILURE = 'PROD_CATEGORY_FAILURE';